import App from 'next/app'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { CloudimageProvider } from 'next-cloudimage-responsive'

// eslint-disable-next-line import/namespace, import/default, import/no-named-as-default, import/no-named-as-default-member
import NiceModal from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import reactMultiCarouselStyles from 'react-multi-carousel/lib/styles.css'

import { getShopConstants } from '@tofu/shared/data-access/shop-constants'
import { client } from '@tofu/shared/utils/axios-client'
import { FiltersProvider } from '@tofu/shared/providers/filters-provider'
import { UIProvider } from '@tofu/shop/providers/ui-provider'
import { StatsigTestProvider } from '@tofu/shared/providers/statsig-test-provider'

import rhizTheme, { ThemeProvider } from '@tofu/shared/theme'
import { BasketProvider } from '@tofu/apps/shop/providers/basket'
import { FeedbackProvider } from '@tofu/apps/shop/providers/feedback'
import { HistoryProvider } from '@tofu/apps/shop/providers/history'
import { ModalProvider } from '@tofu/apps/shop/providers/modal'
import { PrismicDataProvider } from '@tofu/apps/shop/providers/prismic'
import { ReactQueryProvider } from '@tofu/shared/providers/react-query-provider'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GlobalTracking } from '@tofu/shop/ui/organisms/global-tracking'
import { SessionProvider } from '@tofu/apps/shop/providers/session'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AffiliateTracking } from '@tofu/shop/ui/molecules/affiliate-tracking'

import global from '@tofu/apps/shop/styles/global'
import { calendarStyles } from '@tofu/shared/ui/molecules/delivery-calendar'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ConstantsProvider } from '@tofu/shared/providers/constants-provider'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getGlobalShopSettings } from '@tofu/shop/data-access/prismic'
import { GlobalShopContentProvider } from '@tofu/shop/providers/global-shop-content-provider'
import { environment } from '@tofu/shared/constants/environments'

/**
 * When developing locally or testing in cypress
 * use mocked endpoints / data from @tofu/mocks
 */
if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('@tofu/mocks')
  console.warn('Using mocked data. Should never see this in production!')
}

const GlobalStyle = createGlobalStyle`
  ${global}
  ${calendarStyles}
  ${reactMultiCarouselStyles}
`

const cloudimageConfig = {
  baseURL: environment.shop_base_url,
  token: environment.cloudimage_token
}

export default function ShopApp({
  Component,
  pageProps,
  err,
  globalShopSettings,
  constants
}) {
  const { events } = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      // optin monster global embed code is included using GTM
      if (window.om74754_66485) {
        // reset optin monster on page load to allow inline campaigns in SPA - https://optinmonster.com/docs/using-the-optinmonster-api-with-single-page-applications/
        window.om74754_66485.reset()
      }
    }

    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events])

  return (
    <ReactQueryProvider pageProps={pageProps}>
      <CloudimageProvider config={cloudimageConfig}>
        <SessionProvider>
          <FeedbackProvider>
            <ThemeProvider theme={rhizTheme}>
              <UIProvider>
                <GlobalShopContentProvider
                  defaultPromoBanner={globalShopSettings?.default_promo_banner}
                >
                  <ConstantsProvider constants={constants}>
                    <BasketProvider>
                      <FiltersProvider>
                        <PrismicDataProvider>
                          <GlobalStyle />
                          <GlobalTracking />
                          <HistoryProvider>
                            <ModalProvider>
                              <NiceModal.Provider>
                                <StatsigTestProvider>
                                  <Component {...pageProps} err={err} />
                                </StatsigTestProvider>
                                <AffiliateTracking />
                              </NiceModal.Provider>
                            </ModalProvider>
                          </HistoryProvider>
                        </PrismicDataProvider>
                      </FiltersProvider>
                    </BasketProvider>
                  </ConstantsProvider>
                </GlobalShopContentProvider>
              </UIProvider>
            </ThemeProvider>
          </FeedbackProvider>
        </SessionProvider>
      </CloudimageProvider>
    </ReactQueryProvider>
  )
}

// we can remove this work around in NextJS 13
// https://github.com/i18next/next-i18next/issues/615#issuecomment-575578375

ShopApp.getInitialProps = async (context) => {
  const pageProps = await App.getInitialProps(context) // Retrieves page's `getInitialProps`

  const constants = await getShopConstants(client)
  const globalShopSettings = await getGlobalShopSettings()

  return {
    ...pageProps,
    constants,
    globalShopSettings
  }
}

App.defaultProps = {
  err: undefined,
  pageProps: undefined
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  err: PropTypes.shape({}),
  pageProps: PropTypes.shape({})
}
